<!--货款发票 -->
<template>
	<div :class="themeClass">
		<div class="margin-t-20 myPointsContent">
			<div class="flex-row-align-flex-end margb" style="align-items: center;">
				<div class="title font-size18 font-weight700 font-color-333" style="margin-right: 20px;">
					中奖记录
				</div>
				<div class=" margin-r-10 ">
					    <el-date-picker
							 v-model="beginDate"
							 value-format="yyyy"
							 type="year"
							 placeholder="选择年">
						</el-date-picker>
				</div>
				<div class="allOrder-btn">
					<div type="primary" class="background-color-theme zad pointer" @click="searchqingdan">查询</div>
				</div>
			</div>
			<!-- tab切换 -->
			<div class="tabTitleRow pointer" v-if='tabData.length >0'>
				<div v-for="(item,index) in tabData" :key="index" :class="item.checked?'checkTitleSty':''" @click="selecttab(index)">
					{{item.title}}
				</div>
			</div>
			<!-- 发票清单   tableData1  -->
				<div  v-for='(item,index) of orderList' :key='index'>
					<div v-if="selectid== item.fAwardPoolID">
					<el-table    :data="item.fDetail" highlight-current-row  v-loading="tableLoading" border height='400px' style="margin-top: 10px;width:100%"
						ref="multipleTable" :row-class-name="tableRowClassName" :header-cell-style="{ 'text-align': 'center', background: '#F7F9FE' }">
										<el-table-column v-for="(item, index) in tableDetailLabel"  :key="index" :prop="item.prop" :width="item.width" :label="item.label"
										 :align="item.align" :fixed="item.fixed" show-overflow-tooltip />
					</el-table>
					</div>
				</div>
		</div>
	</div>
</template>
<script>
	import {
		mapGetters
	} from "vuex";
	import pagination from '@/components/pagination/pagination';
	export default {
		components: {
			pagination
		},
		data() {
			return {
				orderList:[],
				selectid:'',
				tableLoading:false,
				beginDate:"",
				total: 0, // 条数
				page: 1, // 页数
				maxResultCount: 20,
				total1: 0, // 条数
				page1: 1, // 页数
				maxResultCount1: 20,
				pdfClick: true, //导出pdf直到接口返回成功或者失败才能再次点击
				excelClick: true, //导出excel直到接口返回成功或者失败才能再次点击
				shopList: '',
				shopArr: [{
					shopneme: '5'
				}],
				activeName: "sevendays",
				searchDate: [new Date(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 7)), new Date()],
				value: "",
				listShow: 0,
				defaultTime: '', //时间默认值
				tableData: [],
				tableData1:[],
				tabData: [
				],
				tableDetailLabel: [
									{
										label: '奖品名称',
										width: '240',
										prop: 'fAwardName',
										align: 'left',
										type: 'string',
									},
									{
										label: '奖品等级',
										width: '180',
										prop: 'fPrizeGradeName',
										align: 'left',
										type: 'string',
									},
									{
										label: '使用积分',
										prop: 'fUsePoint',
										align: 'right',
										width: '150',
										type: 'number',
									},
								
									{
										label: '抽奖人',
										width: '150',
										prop: 'fCreator',
										align: 'left',
										type: 'string',
									},
									{
										label: '抽奖时间',
										width: '200',
										prop: 'fCreateTime',
										align: 'center',
										type: 'time',
									},
								],
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			this.value = this.getYM();
			const t = new Date()
			var y = t.getFullYear()
						this.beginDate = y+''
						this.getList()
		},
		methods: {
			searchqingdan(){
				console.log(this.listShow)
				this.getList()
			},
			//表格斑马线
			tableRowClassName(rowIndex) {
							if (rowIndex.rowIndex % 2 === 0) {
								return 'warning-row'
							} else if (rowIndex.rowIndex % 2 === 1) {
								return 'success-row'
							}
						},
			getList() {
							this.tableLoading = true;
							this.ApiRequestPostNOMess(
								"/api/mall/ebactivity/luck-draw-record/get-by-curret-user-list", {
									fYear:this.beginDate,
								}
							).then(
								(result) => {
									this.tableLoading = false;
									 this.orderList = []
									 this.tabData = []
									 if(result.obj && result.obj.length >0){
										for(let item  of result.obj){
									 			for(let sonitem of item.fDetail){
									 				if(sonitem.fCreateTime){
									 										sonitem.fCreateTime = this.getTime(sonitem.fCreateTime)
									 									}
									 				if(sonitem.fCreateTime){
									 										sonitem.fModifyTime = this.getTime(sonitem.fModifyTime)
									 									}
									 								}
									 							}
									 						}
									for(let item of result.obj){
											this.tabData.push({
												title:item.fAwardPoolName,
												type:item.fAwardPoolID,
												checked:false,			   
															   })
									}
									this.tabData[0].checked = true
									this.orderList = result.obj
									if(this.orderList && this.orderList.length >0){
										this.selectid = this.orderList[0].fAwardPoolID;
									}
									console.log(result)
								
								},
								(error) => {
									this.tableLoading = false;
								}
							);
						},
			//tab点击事件
			selecttab(e) {
				console.log(e, '111111');
				for (let item of this.tabData) {
					item.checked = false;
				}
				this.tabData[e].checked = true;
				this.selectid = this.orderList[e].fAwardPoolID;
			},
			NumFormat(value) {
			        if (!value) return '0.00';
			        value = Number(value).toFixed(2).toString();
			        var intPart = parseInt(Number(value)); //获取整数部分
			        var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断
			        var floatPart = ".00"; //预定义小数部分
			        var value2Array = value.split(".");
			        //=2表示数据有小数位
			        if (value2Array.length == 2) {
			          floatPart = value2Array[1].toString(); //拿到小数部分
			          if (floatPart.length == 1) { //补0,实际上用不着
			            return intPartFormat + "." + floatPart + '0';
			          } else {
			            return intPartFormat + "." + floatPart;
			          }
			        } else {
			          return intPartFormat + floatPart;
			        }
			      }
		},
	};
</script>
<style scoped lang="scss">
	.margb {
		margin-bottom: 20px;
	}

	/deep/ .el-input__suffix-inner {
		// line-height: 50px;
		display: none;
	}

	/deep/ .el-tabs__header {
		margin: 0;
	}

	/deep/ .el-tabs__item {
		padding: 0;
		width: 75px;
		height: 32px;
		line-height: 32px;
		text-align: center;
	}

	/deep/ .el-tabs__active-bar {
		display: none;
	}

	/deep/ .el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	.inputBor {
		display: flex;
		border: 1px solid #f2f2f2;

		/deep/.el-input__inner {
			border: 0 !important;
		}

		span {
			line-height: 30px;
		}
	}

	/deep/ .el-input__icon {
		line-height: 22px;
	}

	/deep/ .el-tabs__item.is-active {
		color: #fff;

		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.top {
		width: 100%;
		height: 32px;

		.el-date-editor.el-input,
		.el-date-editor.el-input__inner {
			width: 240px;
		}

		.search-box {
			width: 270px;
		}

		.search-btn {
			width: 79px;
			height: 32px;
			line-height: 32px;
			background: #f8f7fa;
			border-radius: 4px;
			border: 1px solid #f2f2f2;
		}
	}

	/deep/ .el-input {
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-date-editor .el-range__icon {
		height: 32px;
	}

	/deep/ .el-date-editor .el-range-separator {
		height: 32px;
	}

	/deep/ .el-input__suffix-inner {
		line-height: 50px;
	}

	.tabone-num {
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 20px;
		color: #6C40BF;
		text-align: center;
	}

	.tabone-con {
		font-size: 14px;
		color: #6C40BF;
		border-bottom: none;
	}

	.tabone-num1 {
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 20px;
		color: #333333;
	}

	.tabone-con1 {
		font-size: 14px;
		color: #333333;
		text-align: center;
		border-bottom: none;
	}

	.date {
		position: relative;
		top: 2px;
		left: 285px;
	}

	.myPointsContent {
		width: 917px;
		min-height: 485px;
		background-color: #FFFFFF;
		// padding:0px 13px;
	}

	.allOrder-btn {
		display: flex;
		// position: relative;	
		// left: 295px;
	}

	.allOrder-btn div:nth-child(1) {
		width: 75px;
		height: 32px;
		margin-left: 10px;
		margin-right: 10px;
		text-align: center;
		line-height: 32px;
	}

	.allOrder-btn div:nth-child(2) {
		width: 110px;
		height: 32px;
		text-align: center;
		line-height: 32px;
		margin-right: 10px;
	}

	.allOrder-btn div:nth-child(3) {
		width: 110px;
		height: 32px;
		text-align: center;
		line-height: 32px;
	}

	.block {
		margin-left: 260px;
		margin-right: 10px;
	}

	::v-deep .el-table__row {
		height: 38px;
	}

	.red {
		color: #ff0000;
	}

	.black {
		color: #000;
	}

	/* tab切换标题 */
	.tabTitleRow {
		height: 40px;
		background-color: #F8F9FE;
		border: 1px solid #eee;
		margin-top: 17px;
		box-sizing: border-box;
		margin-bottom: 17px;
	}

	::v-deep .el-table .cell {
		white-space: nowrap;
	}

	::v-deep .el-table thead {
		font-size: 13px;
		color: #000000;
		font-weight: 500;
		text-align: center;
	}

	::v-deep .el-table td,
	::v-deep .el-table th {
		height: 38px;
		padding: 0px 0px;
		text-align: center;
	}

	::v-deep .el-table td,
	::v-deep .el-table th {
		text-align: center;
	}

	::v-deep .is-leaf {
		border-right: 0px solid transparent;
		background-color: #F2F2F2 !important;
	}

	.tabTitleRow div {
		width: 108px;
		display: inline-block;
		height: 40px;
		line-height: 40px;
		color: #666;
		text-align: center;
		box-sizing: border-box;
	}

	.tabTitleRow div:first-child {
		border-left: none !important;
	}

	.tabTitleRow .checkTitleSty {
		@include themify($themes) {
			color: themed("themes_color");
		}

		font-weight: bold;
		background-color: #fff;
		border-left: 1px solid #eee;
		border-right: 1px solid #eee;
	}

	.line-height39 {
		line-height: 39px;
	}

	.billtext {
		line-height: 36px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.aaa {
		width: 800px;
		justify-content: space-evenly;
		margin-top: 20px;
		border-bottom: none;
	}

	.tabOne {
		width: 100%;
		height: 135px;
		background: #F8F5FC;
		border-radius: 2px;
		border: 1px solid #6C40BF;
		margin-top: 10px;
		position: relative;
	}

	.tabOne-Title {
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 15px;
		color: #6C40BF;
		margin: 10px 0px 10px 10px;
	}

	.tabOne img {
		width: 180px;
		height: 119px;
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.topbgc {
		// width: 917px;
		height: 35px;
		background: #f8f9fe;
		padding: 0 15px;
		margin: 11px auto;
	}

	::v-deep.el-input--suffix .el-input__inner {
		line-height: 32px !important;
		height: 32px !important;
	}

	::v-deep .el-input__icon {
		line-height: 32px !important;
	}

	.billsitems {
		width: 100%;
		height: 64px;
		border-bottom: 1px solid #f2f2f2;
	}

	.allOrder-btn .zad {
		/* position: absolute;
		left: 1095px; */
	}

	.allOrder-btn .ad {
		/* position: absolute;
		left: 1175px; */
	}
	
</style>
